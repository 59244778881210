import React from 'react';

const StoryContent = ({ story, currentSentence, fontSize, fontFamily, fadeState, handleWordClick, handleWordLookup, theme }) => (
  <div className="bg-white rounded-2xl shadow-lg p-8 w-full max-w-3xl mb-6 relative">
    <p className={`${fontSize} ${fontFamily} mb-6 transition-all duration-300 leading-relaxed break-words ${
      fadeState === 'out' ? 'opacity-0' : 'opacity-100'
    }`}>
      {story?.content[currentSentence].split(' ').map((word, index) => (
        <span
          key={index}
          className="inline-block hover:bg-yellow-200 cursor-pointer transition-colors my-1 mr-2 px-1 rounded"
          onClick={() => {
            handleWordClick(word);
            handleWordLookup(word);
          }}
        >
          {word}
        </span>
      ))}
    </p>
  </div>
);

export default StoryContent;