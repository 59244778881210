import React, { useState } from "react";
import { Volume2, VolumeX, Palette, X } from "lucide-react";
import logo from "../logo.png";
import LeftPanel from "./LeftPanel";
import StoryContent from "./StoryContent";
import NavigationButtons from "./NavigationButtons";
import FontToolbar from "./FontToolbar";
import ProgressBars from "./ProgressBars";

// Constants
import { themes, fontSizes, fontFamilies } from "../constants/themeConstants";

const StoryReaderView = ({
  isDropdownOpen,
  setIsDropdownOpen,
  availableStories,
  currentStoryIndex,
  setCurrentStoryIndex,
  stories,
  currentChapter,
  goToChapter,
  fadeState,
  handleWordClick,
  prevSentence,
  nextSentence,
  transitionEffect,
  isLoading,
  currentSentence,
  availableCollections,
  currentCollectionIndex,
  setCurrentCollectionIndex,
  handleCollectionChange,
  handleStoryChange,
  chapterProgress,
  storyProgress,
  isPlaying,
  currentTheme,
  toggleTheme,
}) => {
  // State hooks
  const [isCollectionDropdownOpen, setIsCollectionDropdownOpen] =
    useState(false);
  const [isLeftPanelCollapsed, setIsLeftPanelCollapsed] = useState(false);
  const [fontSize, setFontSize] = useState("text-2xl");
  const [fontFamily, setFontFamily] = useState("font-sans");
  const [showFontToolbar, setShowFontToolbar] = useState(false);
  const [fontToolbarPosition, setFontToolbarPosition] = useState({
    top: 0,
    left: 0,
  });
  const [dictionaryDefinition, setDictionaryDefinition] = useState("");
  const [dictionaryWord, setDictionaryWord] = useState("");
  const [isDictionaryVisible, setIsDictionaryVisible] = useState(false);

  // Helper functions
  const increaseFontSize = () => {
    const currentIndex = fontSizes.indexOf(fontSize);
    if (currentIndex < fontSizes.length - 1) {
      setFontSize(fontSizes[currentIndex + 1]);
    }
  };

  const decreaseFontSize = () => {
    const currentIndex = fontSizes.indexOf(fontSize);
    if (currentIndex > 0) {
      setFontSize(fontSizes[currentIndex - 1]);
    }
  };

  const speakSentence = () => {
    const text = stories[currentChapter]?.content[currentSentence];
    if (text) {
      handleWordClick(text);
    }
  };

  const toggleFontToolbar = (event) => {
    if (showFontToolbar) {
      setShowFontToolbar(false);
    } else {
      const buttonRect = event.currentTarget.getBoundingClientRect();
      setFontToolbarPosition({
        top: buttonRect.height,
        left: -(buttonRect.width / 2),
      });
      setShowFontToolbar(true);
    }
  };

  const handleWordLookup = async (word) => {
    const cleanWord = word.replace(/[^\w\s]|_/g, "").replace(/\s+/g, " ");
    try {
      const response = await fetch(
        `https://www.dictionaryapi.com/api/v3/references/learners/json/${cleanWord}?key=${process.env.REACT_APP_DICTIONARY_API}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch definition");
      }
      const data = await response.json();
      setDictionaryWord(cleanWord);
      setDictionaryDefinition(data[0]?.shortdef[0] || "No definition found");
      setIsDictionaryVisible(true);
    } catch (error) {
      setDictionaryWord(cleanWord);
      setDictionaryDefinition("Error fetching definition");
    }
  };

  // Render
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (stories.length === 0) {
    return <div>No stories available.</div>;
  }

  return (
    <div
      className={`min-h-screen ${themes[currentTheme].background} flex flex-col font-sans`}
    >
      <main className="flex-grow flex flex-col md:flex-row p-6 overflow-hidden">
        <LeftPanel
          isCollapsed={isLeftPanelCollapsed}
          setIsCollapsed={setIsLeftPanelCollapsed}
          isCollectionDropdownOpen={isCollectionDropdownOpen}
          setIsCollectionDropdownOpen={setIsCollectionDropdownOpen}
          isDropdownOpen={isDropdownOpen}
          setIsDropdownOpen={setIsDropdownOpen}
          availableStories={availableStories}
          currentStoryIndex={currentStoryIndex}
          setCurrentStoryIndex={setCurrentStoryIndex}
          stories={stories}
          currentChapter={currentChapter}
          goToChapter={goToChapter}
          availableCollections={availableCollections}
          currentCollectionIndex={currentCollectionIndex}
          setCurrentCollectionIndex={setCurrentCollectionIndex}
          handleCollectionChange={handleCollectionChange}
          handleStoryChange={handleStoryChange}
          theme={themes[currentTheme]}
        />
        <div
          className={`flex-grow flex flex-col items-center justify-start overflow-y-auto transition-all duration-300 ${
            isLeftPanelCollapsed ? "ml-0 w-full" : "ml-0 md:ml-8"
          } mt-6 md:mt-0`}
        >
          <h2
            className={`text-3xl font-bold mb-6 ${themes[currentTheme].text}`}
          >
            {stories[currentChapter]?.title}
          </h2>
          <StoryContent
            story={stories[currentChapter]}
            currentSentence={currentSentence}
            fontSize={fontSize}
            fontFamily={fontFamily}
            fadeState={fadeState}
            handleWordClick={handleWordClick}
            handleWordLookup={handleWordLookup}
            theme={themes[currentTheme]}
          />
          <NavigationButtons
            prevSentence={prevSentence}
            nextSentence={nextSentence}
            currentChapter={currentChapter}
            currentSentence={currentSentence}
            storiesLength={stories.length}
            currentStoryLength={stories[currentChapter]?.content.length}
            transitionEffect={transitionEffect}
            theme={themes[currentTheme]}
          />

          {isDictionaryVisible && (
            <div
              className={`mt-4 p-4 rounded-lg shadow-inner ${themes[currentTheme].background} ${themes[currentTheme].text}`}
            >
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-semibold">Word Definition</h3>
                <button onClick={() => setIsDictionaryVisible(false)}>
                  <X size={20} />
                </button>
              </div>
              <p>
                <strong>{dictionaryWord}:</strong> {dictionaryDefinition}
              </p>
            </div>
          )}

          <ProgressBars
            chapterProgress={chapterProgress}
            storyProgress={storyProgress}
            theme={themes[currentTheme]}
          />

          <div className="flex space-x-4 mb-6">
            <FontToolbar
              toggleFontToolbar={toggleFontToolbar}
              showFontToolbar={showFontToolbar}
              fontToolbarPosition={fontToolbarPosition}
              decreaseFontSize={decreaseFontSize}
              increaseFontSize={increaseFontSize}
              setFontFamily={setFontFamily}
              fontFamily={fontFamily}
              fontFamilies={fontFamilies}
            />
            <button
              onClick={speakSentence}
              disabled={isPlaying}
              className={`p-2 bg-white rounded-full shadow-md hover:bg-gray-100 z-20 ${
                isPlaying ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isPlaying ? <VolumeX size={20} /> : <Volume2 size={20} />}
            </button>
            <button
              onClick={toggleTheme}
              className={`p-2 bg-white rounded-full shadow-md hover:bg-gray-100 z-20`}
            >
              <Palette size={20} />
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default StoryReaderView;
