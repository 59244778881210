import { Link, useLocation } from 'react-router-dom';

const Header = ({ logo, theme }) => {
  const headerClass = theme.header || 'bg-gray-800';
  const buttonClass = theme.button || 'bg-blue-500 hover:bg-blue-600';
  const location = useLocation();

  const isProfilePage = location.pathname === '/profile';
  const isAboutPage = location.pathname === '/about';

  return (
    <header className={`${headerClass} text-white p-6 flex justify-between items-center shadow-lg`}>
      <div className="flex items-center">
        <Link to="/">
          <img src={logo} alt="Logo" className="h-[60px] mr-4" />
        </Link>
        <h1 className="text-3xl font-bold">It's Time to Read</h1>
      </div>
      <div className="flex items-center">
        <Link 
          to={isProfilePage ? "/" : "/profile"} 
          className={`${buttonClass} mr-4 px-4 py-2 rounded`}
        >
          {isProfilePage ? "Home" : "Profile"}
        </Link>
        <Link 
          to={isAboutPage ? "/" : "/about"} 
          className={`${buttonClass} mr-4 px-4 py-2 rounded`}
        >
          {isAboutPage ? "Home" : "About"}
        </Link>
      </div>
    </header>
  );
};

export default Header;