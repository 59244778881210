import React from 'react';
import { themes } from '../constants/themeConstants';

const AboutPage = ({ theme = themes.default }) => {
  return (
    <div className={`container mx-auto mt-8 p-4`}>
      <h1 className={`text-3xl font-bold mb-4 ${theme.text}`}>About Us</h1>
      <div className={`${theme.highlight} shadow-md rounded px-8 pt-6 pb-8 mb-4`}>
        <p className={`${theme.text} mb-4`}>
            Welcome to <strong>It's Time to Read</strong>, an innovative interactive reading app designed to make learning fun and accessible for young readers. Our mission is to empower children with the tools they need to develop confidence and joy in reading.
        </p>
        <p className={`${theme.text} mb-4`}>
            At the heart of our application is the belief that reading should be a rewarding adventure. Whether your child is just starting their reading journey or looking to enhance their skills, we offer a supportive and engaging environment tailored to their needs.
        </p>
        <p className={`${theme.text} mb-4`}>
            <strong>It's Time to Read</strong> features interactive sentence-by-sentence reading, word pronunciation support, and text-to-speech functionality, ensuring a smooth learning experience for every young reader. Our diverse story collections, including "Stories of the Prophets" and "Reddy the Fox," and "First Reader" are thoughtfully designed to captivate young minds and inspire curiosity.
        </p>
        <p className={`${theme.text} mb-4`}>
            We are committed to making reading accessible to everyone. With customizable text sizes and color schemes, progress tracking, and a responsive design, children can enjoy reading anytime, anywhere. Personalized user accounts also help save preferences and track individual progress.
        </p>
        <p className={`${theme.text} mb-4`}>
            This is an alpha version of the application, and we are continuously working to improve and expand our features. Your feedback is invaluable in helping us create the best reading experience possible. Please share your thoughts, suggestions, or concerns by reaching out to <a href="https://instagram.com/qamarun.shadow" target="_blank" rel="noopener noreferrer">@qamarun.shadow</a> on Instagram or Twitter.
        </p>
        <p className={`${theme.text} mb-4`}>
            Thank you for choosing <strong>It's Time to Read</strong>. Together, let's make reading an exciting journey. Happy reading!
        </p>
      </div>
    </div>
  );
};

export default AboutPage;