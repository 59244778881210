import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import StoryReader from './components/StoryReader';
import AccountProfile from './components/AccountProfile';
import AboutPage from './components/AboutPage';
import Header from './components/Header';
import logo from './logo.png';

// Import or define your theme
import { themes } from './constants/themeConstants';

function App() {
  const [currentTheme, setCurrentTheme] = useState('default');

  const toggleTheme = () => {
    const themeKeys = Object.keys(themes);
    const currentIndex = themeKeys.indexOf(currentTheme);
    const nextIndex = (currentIndex + 1) % themeKeys.length;
    setCurrentTheme(themeKeys[nextIndex]);
  };

  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header logo={logo} theme={themes[currentTheme]} />
        <main className="flex-grow">
          <Routes>
            <Route 
              path="/" 
              element={<StoryReader currentTheme={currentTheme} toggleTheme={toggleTheme} />} 
            />
            <Route path="/profile" element={<AccountProfile theme={themes[currentTheme]} />} />
            <Route path="/about" element={<AboutPage theme={themes[currentTheme]} />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;