import React, { useState, useEffect, useCallback, useRef } from 'react';
import StoryReaderView from './StoryReaderView';

const StoryReader = ({ currentTheme, toggleTheme }) => {
  const [stories, setStories] = useState([]);
  const [currentChapter, setCurrentChapter] = useState(0);
  const [currentSentence, setCurrentSentence] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [storyTitle, setStoryTitle] = useState('');
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  const [showTOC, setShowTOC] = useState(false);
  const [availableStories, setAvailableStories] = useState([]);
  const [progress, setProgress] = useState(0);
  const [transitionEffect, setTransitionEffect] = useState('');
  const [fadeState, setFadeState] = useState('in');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [availableCollections, setAvailableCollections] = useState([]);
  const [currentCollectionIndex, setCurrentCollectionIndex] = useState(0);
  const [chapterProgress, setChapterProgress] = useState(0);
  const [storyProgress, setStoryProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const utteranceRef = useRef(null);

  useEffect(() => {
    loadAvailableCollections();
  }, []);


  const loadAvailableCollections = async () => {
    try {
      console.log('Attempting to fetch collections index...');
      const response = await fetch('/collections/index.json');
      console.log('Fetch response:', response);
      if (!response.ok) {
        throw new Error(`Failed to fetch collections index: ${response.status} ${response.statusText}`);
      }
      const collectionsIndex = await response.json();
      console.log('Collections index content:', JSON.stringify(collectionsIndex, null, 2));
      
      setAvailableCollections(collectionsIndex);
      if (collectionsIndex.length > 0) {
        setCurrentCollectionIndex(0);
        const firstCollection = collectionsIndex[0];
        console.log('Setting available stories:', firstCollection.stories);
        setAvailableStories(firstCollection.stories);
        if (firstCollection.stories.length > 0) {
          const firstStory = firstCollection.stories[0];
          console.log('Loading first story:', firstStory);
          loadStory(firstCollection.id, firstStory.id);
        }
      }
    } catch (error) {
      console.error('Error loading collections index:', error);
      setIsLoading(false);
    }
  };

  const handleCollectionChange = (newCollectionIndex) => {
    const newCollection = availableCollections[newCollectionIndex];
    if (newCollection) {
      setCurrentCollectionIndex(newCollectionIndex);
      setAvailableStories(newCollection.stories);
      if (newCollection.stories.length > 0) {
        setCurrentStoryIndex(0);
        loadStory(newCollection.id, newCollection.stories[0].id);
      }
    }
  };

  const loadStory = async (collectionId, storyId) => {
    console.log('loadStory called with collectionId:', collectionId, 'storyId:', storyId);
    try {
      if (!collectionId || !storyId) {
        throw new Error(`Invalid collection ID (${collectionId}) or story ID (${storyId})`);
      }
      console.log(`Attempting to fetch story: ${collectionId}/${storyId}`);
      const response = await fetch(`/collections/${collectionId}/${storyId}/story.txt`);
      console.log('Story fetch response:', response);
      console.log('Response status:', response.status);
      console.log('Response headers:', response.headers);
      
      if (!response.ok) {
        throw new Error(`Failed to fetch story: ${response.status} ${response.statusText}`);
      }
      
      const text = await response.text();
      
      const parsedStory = parseStoryText(text);
      console.log('Parsed story:', parsedStory);
      setStoryTitle(parsedStory.title);
      setStories(parsedStory.chapters);
      setCurrentChapter(0);
      setCurrentSentence(0);
    } catch (error) {
      console.error('Error loading story:', error);
      setStories([]);
      setStoryTitle('Error loading story');
    } finally {
      setIsLoading(false);
    }
  };

  const parseStoryText = (text) => {
    const lines = text.split('\n');
    const title = lines[0].replace('# ', '');
    const chapters = [];
    let currentChapter = null;
    let currentParagraph = '';
    let isInsideQuote = false;

    for (let i = 1; i < lines.length; i++) {
      const line = lines[i].trim();
      if (line.startsWith('## ')) {
        if (currentChapter) {
          if (currentParagraph) {
            addSentencesToChapter(currentChapter, currentParagraph);
            currentParagraph = '';
          }
          chapters.push(currentChapter);
        }
        currentChapter = { title: line.replace('## ', ''), content: [] };
        isInsideQuote = false;
      } else if (line && currentChapter) {
        if (isInsideQuote) {
          currentParagraph += ' ' + line;
        } else {
          currentParagraph += (currentParagraph ? ' ' : '') + line;
        }
        
        const quoteCount = (line.match(/"/g) || []).length;
        isInsideQuote = quoteCount % 2 !== 0 ? !isInsideQuote : isInsideQuote;
      } else if (!line && currentParagraph && currentChapter) {
        addSentencesToChapter(currentChapter, currentParagraph);
        currentParagraph = '';
        isInsideQuote = false;
      }
    }

    if (currentChapter && currentParagraph) {
      addSentencesToChapter(currentChapter, currentParagraph);
      chapters.push(currentChapter);
    }

    return { title, chapters };
  };

  const addSentencesToChapter = (chapter, paragraph) => {
    const sentences = paragraph.match(/[^.!?]+[.!?"]+/g) || [paragraph];
    chapter.content.push(...sentences.map(s => s.trim()));
  };

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'ArrowLeft') {
      prevSentence();
    } else if (event.key === 'ArrowRight') {
      nextSentence();
    }
  }, [stories, currentChapter, currentSentence]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown, stories, currentChapter, currentSentence]);

  const triggerTransitionEffect = (direction) => {
    setFadeState('out');
    setTransitionEffect(direction);
    
    setTimeout(() => {
      if (direction === 'right') {
        nextSentenceChange();
      } else {
        prevSentenceChange();
      }
      setFadeState('in');
    }, 150); // Half of the transition duration

    setTimeout(() => {
      setTransitionEffect('');
    }, 300); // Full transition duration
  };

  const nextSentenceChange = () => {
    if (stories.length === 0 || currentChapter >= stories.length) {
      return;
    }

    const currentChapterContent = stories[currentChapter].content;
    if (!currentChapterContent) {
      return;
    }

    if (currentSentence < currentChapterContent.length - 1) {
      setCurrentSentence(currentSentence + 1);
    } else if (currentChapter < stories.length - 1) {
      setCurrentChapter(currentChapter + 1);
      setCurrentSentence(0);
    }
  };

  const prevSentenceChange = () => {
    if (stories.length === 0 || currentChapter >= stories.length) {
      return;
    }

    if (currentSentence > 0) {
      setCurrentSentence(currentSentence - 1);
    } else if (currentChapter > 0) {
      const previousChapter = stories[currentChapter - 1];
      if (previousChapter && previousChapter.content) {
        setCurrentChapter(currentChapter - 1);
        setCurrentSentence(previousChapter.content.length - 1);
      }
    }
  };

  const nextSentence = () => {
    triggerTransitionEffect('right');
  };

  const prevSentence = () => {
    triggerTransitionEffect('left');
  };

  const goToChapter = (index) => {
    setCurrentChapter(index);
    setCurrentSentence(0);
    setShowTOC(false);
  };

  const handleWordClick = (word) => {
    if (isPlaying) {
      // If already playing, stop the current playback
      if (utteranceRef.current) {
        speechSynthesis.cancel();
      }
      setIsPlaying(false);
      return;
    }

    setIsPlaying(true);
    const utterance = new SpeechSynthesisUtterance(word);
    utteranceRef.current = utterance;

    utterance.onend = () => {
      setIsPlaying(false);
      utteranceRef.current = null;
    };

    utterance.onerror = () => {
      setIsPlaying(false);
      utteranceRef.current = null;
    };

    speechSynthesis.speak(utterance);
  };

  const handleWordLookup = async (word) => {
    try {
      const response = await fetch(`https://api.dictionaryapi.dev/api/v2/entries/en/${word}`);
      if (!response.ok) {
        throw new Error('Failed to fetch definition');
      }
      const data = await response.json();
      setDictionaryDefinition(data[0]?.meanings[0]?.definitions[0]?.definition || 'No definition found');
    } catch (error) {
      setDictionaryDefinition('Error fetching definition');
    }
  };

  const calculateProgress = () => {
    // Calculate chapter progress
    const currentChapterContent = stories[currentChapter]?.content || [];
    const chapterProgressValue = ((currentSentence + 1) / currentChapterContent.length) * 100;
    setChapterProgress(chapterProgressValue);

    // Calculate overall story progress
    const totalSentences = stories.reduce((sum, chapter) => sum + chapter.content.length, 0);
    const sentencesRead = stories.slice(0, currentChapter).reduce((sum, chapter) => sum + chapter.content.length, 0) + currentSentence + 1;
    const storyProgressValue = (sentencesRead / totalSentences) * 100;
    setStoryProgress(storyProgressValue);
  };

  useEffect(() => {
    if (stories.length > 0) {
      calculateProgress();
    }
  }, [currentChapter, currentSentence, stories]);

  const handleStoryChange = (newStoryIndex) => {
    const newStory = availableStories[newStoryIndex];
    console.log('handleStoryChange - newStoryIndex:', newStoryIndex);
    console.log('handleStoryChange - newStory:', newStory);
    if (newStory) {
      setCurrentStoryIndex(newStoryIndex);
      const currentCollection = availableCollections[currentCollectionIndex];
      console.log('Loading story with collectionId:', currentCollection.id, 'storyId:', newStory.id);
      loadStory(currentCollection.id, newStory.id);
    } else {
      console.error('No story found for index:', newStoryIndex);
    }
  };

  return (
    <StoryReaderView
      isDropdownOpen={isDropdownOpen}
      setIsDropdownOpen={setIsDropdownOpen}
      availableStories={availableStories}
      currentStoryIndex={currentStoryIndex}
      setCurrentStoryIndex={setCurrentStoryIndex}
      loadStory={loadStory}
      stories={stories}
      currentChapter={currentChapter}
      goToChapter={goToChapter}
      fadeState={fadeState}
      handleWordClick={handleWordClick}
      progress={progress}
      prevSentence={prevSentence}
      nextSentence={nextSentence}
      transitionEffect={transitionEffect}
      isLoading={isLoading}
      storyTitle={storyTitle}
      currentSentence={currentSentence}
      handleStoryChange={handleStoryChange}
      availableCollections={availableCollections}
      currentCollectionIndex={currentCollectionIndex}
      setCurrentCollectionIndex={setCurrentCollectionIndex}
      handleCollectionChange={handleCollectionChange}
      chapterProgress={chapterProgress}
      storyProgress={storyProgress}
      isPlaying={isPlaying}
      currentTheme={currentTheme}
      toggleTheme={toggleTheme}
      handleWordLookup={handleWordLookup}
    />
  );
};

export default StoryReader;