import React from 'react';
import { themes } from '../constants/themeConstants';

const AccountProfile = ({ theme = themes.default }) => {
  return (
    <div className={`container mx-auto mt-8 p-4`}>
      <h1 className={`text-3xl font-bold mb-4 ${theme.text}`}>Account Profile</h1>
      <div className={`${theme.highlight} shadow-md rounded px-8 pt-6 pb-8 mb-4`}>
        <div className="mb-4 text-center">
          <div className="w-24 h-24 rounded-full mx-auto bg-gray-300 flex items-center justify-center">
            <span className="text-4xl">👤</span>
          </div>
        </div>
        <div className="mb-4">
          <label className={`block ${theme.text} text-sm font-bold mb-2`}>Name</label>
          <p className={theme.text}>Guest User</p>
        </div>
        <div className="mb-4">
          <label className={`block ${theme.text} text-sm font-bold mb-2`}>Status</label>
          <p className={theme.text}>You are using the app in guest mode. No login required!</p>
        </div>
      </div>
    </div>
  );
};

export default AccountProfile;